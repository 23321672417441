"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function waitForCssLoad() {
    return new Promise(function (resolve, reject) {
        var interval = setInterval(function () {
            if (APP.cssLoaded) {
                clearInterval(interval);
                return resolve();
            }
        }, 10);
    });
}
exports.waitForCssLoad = waitForCssLoad;
