//Inject browser-sync-client script
var NODE_ENV = typeof NODE_ENV_TYPE !== 'undefined' ? NODE_ENV_TYPE : 'undefined';
var ENABLE_BROWSERSYNC = typeof BROWSERSYNC_ENABLED !== 'undefined' ? BROWSERSYNC_ENABLED : false;
if (NODE_ENV === 'development' && ENABLE_BROWSERSYNC) {
    var body = document.getElementsByTagName('body').item(0);
    var script = document.createElement('script');
    var scriptSource = '//HOST:3000/browser-sync/browser-sync-client.js?v=2.18.12'
        .replace("HOST", location.hostname);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', scriptSource);
    body.appendChild(script);
}
