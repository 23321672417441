"use strict";
/**
 * A special exported onStart init method is needed only when a module needs to be executed dynamically,
 * such as after an ajax call.
 *
 * Otherwise, write your code as normal
 */
Object.defineProperty(exports, "__esModule", { value: true });
var onStart = function () {
    console.log("Language page init");
};
exports.default = { onStart: onStart };
