"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var env_1 = require("../helpers/env");
var $ = require("jquery");
var wait_for_css_load_1 = require("./wait-for-css-load");
var _MainMenu = /** @class */ (function () {
    function _MainMenu() {
        var _this = this;
        this.toggleMenu = function (e) {
            e.preventDefault();
            _this.$secondaryMenu.toggleClass('open');
            _this.$toggleButton.toggleClass('menu__toggle--open');
        };
        this.getMainMenuTotalWidth = function () {
            return _this.$mainMenu.width();
        };
        this.getMainMenuAvailableWidth = function () {
            return _this.getMainMenuTotalWidth() - _this.getMainMenuItemsWidth() - _MainMenu.extraSafetyMargin;
        };
        this.filterOverflowingItems = function (element) {
            var spaceTaken = $(element).prevAll('.menu__nav-item').get().reduce(function (previousValue, currentValue) { return previousValue + currentValue.offsetWidth; }, element.offsetWidth);
            return spaceTaken + _this.$toggleButton.innerWidth() > _this.getMainMenuTotalWidth();
        };
        this.fitItems = function () {
            if (_this.getMainMenuItemsWidth() > _this.getMainMenuTotalWidth()) {
                var $leftovers = _this.$mainMenu.children('.menu__nav-item').get().reverse().filter(_this.filterOverflowingItems);
                $leftovers.forEach(function (item) {
                    $(item).detach().prependTo(_this.$secondaryMenu);
                });
            }
            else {
                _this.$secondaryMenu.children('.menu__nav-item').each(function (i, item) {
                    var $item = $(item);
                    if (_this.getMainMenuAvailableWidth() > $item.children('a').first().width()) {
                        $item.detach().insertBefore(_this.$toggleButton);
                    }
                });
            }
            if (_this.$secondaryMenu.children().length == 0) {
                _this.$toggleButton.hide();
            }
            else {
                _this.$toggleButton.show();
            }
        };
        this.stickyMenu = function () {
            var last_known_scroll_position = window.scrollY;
            var header = document.querySelector('.header__inner');
            if (header.hasChildNodes()) {
                window.addEventListener('scroll', function () {
                    if (env_1.default.isSimple()) {
                        if (window.scrollY > last_known_scroll_position) {
                            _this.headerUnStick(header);
                        }
                        else if (window.scrollY <= last_known_scroll_position - 30) { // don't show the menu immediately
                            _this.headerStick(header);
                        }
                        last_known_scroll_position = window.scrollY;
                        if (last_known_scroll_position == 0) {
                            _this.headerUnStick(header);
                        }
                    }
                    else {
                        _this.headerUnStick(header);
                    }
                });
            }
        };
        this.headerStick = function (e) {
            if (!(e.classList.contains('sticky'))) {
                var saverDiv = document.createElement('div');
                saverDiv.style.height = e.clientHeight.toString() + 'px';
                _this.insertAfter(e, saverDiv);
                e.classList.add('sticky');
                e.classList.add('entrance');
                $('.js-header-search').removeClass('header-search--open');
                setTimeout(function () {
                    e.classList.remove('entrance');
                }, 300);
            }
        };
        this.headerUnStick = function (e) {
            if (e.classList.contains('sticky')) {
                e.classList.remove('sticky');
                e.nextElementSibling.remove();
            }
        };
        this.insertAfter = function (referenceNode, newNode) {
            referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
        };
        this.$toggleButton = $(".js-menu-btn");
        this.$header = $(".header__navigation");
        this.$mainMenu = $(".menu--main");
        this.$secondaryMenu = $(".menu--secondary");
    }
    _MainMenu.prototype.watch = function () {
        var _this = this;
        this.stickyMenu();
        wait_for_css_load_1.waitForCssLoad()
            .then(function () {
            _this.$header.addClass('css-loaded');
            _this.fitItems();
        });
        this.$toggleButton.on('click', this.toggleMenu);
        window.addEventListener("resize", this.fitItems);
        this.$header.addClass('js-menu-loaded');
    };
    ;
    _MainMenu.prototype.getMainMenuItemsWidth = function () {
        return this.$mainMenu.children().get().reduce(function (previousValue, currentValue) { return previousValue + currentValue.offsetWidth; }, 0);
    };
    /**
     * wait for this amount of extra pixels to be available as free space before putting any items in top level
     * @type {number}
     */
    _MainMenu.extraSafetyMargin = 50;
    return _MainMenu;
}());
var MainMenu = new _MainMenu();
exports.default = MainMenu;
