"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("./modules/router");
var menu_1 = require("./modules/menu");
var $ = require("jquery");
require("./modules/browser-sync");
var wait_for_css_load_1 = require("./modules/wait-for-css-load");
require("bootstrap");
// window.$ = window.jQuery = $;
require("slick-carousel");
global.Promise = require("es6-promise");
var routes = {
    'RootController@index': 'language/index',
    'LanguagePageController@index': 'language/index',
};
Object.keys(routes).forEach(function (value, index, array) {
    router_1.default.on(value, routes[value]);
});
router_1.default.run();
if ($('.header__navigation').length > 0) {
    menu_1.default.watch();
}
$('.gallery').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    // centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
});
function subMenu() {
    var $menu = $('.js-submenu');
    if ($menu.length) {
        var $menuItems = $menu.find('li a');
        $menuItems.on('click', function (e) {
            var $this = $(this);
            if ($this.parent().hasClass('active')) {
                e.preventDefault();
                $menu.toggleClass('submenu--open');
            }
        });
    }
}
subMenu();
wait_for_css_load_1.waitForCssLoad()
    .then(function () {
});
window.addEventListener('resize', function () {
});
