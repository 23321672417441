"use strict";
/**
 * Helper methods.
 * Usage:
 * import {throttle} from "helpers/utils";
 * import {throttle, debounce} from "helpers/utils";
 *
 * Unused methods will be dropped during build process.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.throttle = function (fn, _threshold, scope) {
    var threshold = _threshold || 250;
    var last, deferTimer;
    return function () {
        var context = scope || this;
        var now = +new Date(), args = arguments;
        if (last && now < last + threshold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshold);
        }
        else {
            last = now;
            fn.apply(context, args);
        }
    };
};
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
exports.debounce = function (func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate)
                func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            func.apply(context, args);
    };
};
/**
 * Get query param from URL by key
 *
 * @param key
 * @returns string
 */
exports.getQueryParam = function (key) {
    var result = null, tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === key)
            result = decodeURIComponent(tmp[1]);
    });
    return result;
};
/**
 * Update query param
 *
 * @param {string} uri
 * @param {string} key
 * @param {string} value
 * @return {string}
 */
function updateQueryParam(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}
exports.updateQueryParam = updateQueryParam;
/**
 * Vanilla implementation of lodash's _.find( array, function(){})
 *
 * Usage:
 * find([{foo: "bar", boo: "far"}], function(n) {
 *  return n.foo === "bar";
 * });
 *
 * @param array
 * @param predicate
 * @returns {}
 */
function find(array, predicate) {
    if (array == null) {
        throw new TypeError('"array" is null or not defined');
    }
    var len = array.length >>> 0;
    if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
    }
    var thisArg = arguments[1];
    var k = 0;
    while (k < len) {
        var kValue = array[k];
        if (predicate.call(thisArg, kValue, k, array)) {
            return kValue;
        }
        k++;
    }
    return undefined;
}
exports.find = find;
/**
 * Merge objects
 *
 * @param obj
 * @param additional
 * @returns {Object}
 */
function merge(obj, additional) {
    var source, prop;
    for (var i = 1, length_1 = arguments.length; i < length_1; i++) {
        source = arguments[i];
        for (prop in source) {
            obj[prop] = source[prop];
        }
    }
    return obj;
}
exports.merge = merge;
